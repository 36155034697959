var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main apply-delivery-detail" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "van-common-detail" },
        [
          _c("van-cell", {
            attrs: {
              title: "支付批次",
              value: _vm.utils.isEffectiveCommon(_vm.form.paymentNo),
              required: true
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "提单号",
              value: _vm.utils.isEffectiveCommon(_vm.form.cabinetNo),
              required: true
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "订单名称",
              value: _vm.utils.isEffectiveCommon(_vm.form.orderName),
              required: true
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "订单编号",
              value: _vm.utils.isEffectiveCommon(_vm.form.orderNo),
              required: true
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "货物名称",
              value: _vm.utils.isEffectiveCommon(_vm.form.goodsName),
              required: true
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "账面重量(KG)",
              value: _vm.utils.moneyFormat(_vm.stockedDetail.bookWeight),
              required: true
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "当前库存重量(KG)",
              value: _vm.utils.moneyFormat(_vm.stockedDetail.stockWeight),
              required: true
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "可提重量(KG)",
              value: _vm.utils.moneyFormat(
                Number(_vm.stockedDetail.bookWeight) -
                  Number(_vm.stockedDetail.lockWeight)
              ),
              required: true
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "入库日期",
              value: _vm.utils.dateFormat(_vm.stockedDetail.finishInTime),
              required: true
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "仓库名称",
              value: _vm.utils.isEffectiveCommon(_vm.form.warehouseName),
              required: true
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "提货单位",
              value: _vm.utils.isEffectiveCommon(_vm.form.dsEnterpriseName),
              required: true
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "历史盈余金额(元)",
              value: _vm.utils.moneyFormat(_vm.form.dsSurplus),
              required: true
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "原币单价(USD/KG)",
              value: _vm.utils.moneyFormat(_vm.form.unitPrice),
              required: true
            }
          }),
          _c("van-field", {
            attrs: {
              type: "number",
              required: true,
              label: "申请提货数量(KG)",
              "label-width": "9.2em",
              placeholder: "请输入"
            },
            model: {
              value: _vm.form.applyWeight,
              callback: function($$v) {
                _vm.$set(_vm.form, "applyWeight", $$v)
              },
              expression: "form.applyWeight"
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "申请提货日期",
              value: _vm.form.applyDate,
              "is-link": "",
              required: true
            },
            on: {
              click: function($event) {
                _vm.showApplyDate = true
              }
            }
          }),
          _c("van-calendar", {
            on: { confirm: _vm.confirmApplyDate },
            model: {
              value: _vm.showApplyDate,
              callback: function($$v) {
                _vm.showApplyDate = $$v
              },
              expression: "showApplyDate"
            }
          }),
          _c("van-field", {
            attrs: {
              label: "提货联系人",
              "label-width": "9.2em",
              placeholder: "请输入"
            },
            model: {
              value: _vm.form.contacts,
              callback: function($$v) {
                _vm.$set(_vm.form, "contacts", $$v)
              },
              expression: "form.contacts"
            }
          })
        ],
        1
      ),
      _vm._m(1),
      _c("van-cell", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "van-button",
                  {
                    attrs: {
                      plain: "",
                      round: "",
                      size: "small",
                      type: "info",
                      "native-type": "button"
                    },
                    on: {
                      click: function($event) {
                        return _vm.addVehicle()
                      }
                    }
                  },
                  [_vm._v(" 新增装车信息 ")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._l(_vm.form.vehiclesList, function(item, index) {
        return _c(
          "van-cell-group",
          { key: item.id, staticClass: "vehicle-cell-group" },
          [
            _c("van-field", {
              attrs: {
                label: "车牌号",
                "label-width": "9.2em",
                placeholder: "请输入"
              },
              model: {
                value: item.numberPlates,
                callback: function($$v) {
                  _vm.$set(item, "numberPlates", $$v)
                },
                expression: "item.numberPlates"
              }
            }),
            _c("van-field", {
              attrs: {
                label: "联系人",
                "label-width": "9.2em",
                placeholder: "请输入"
              },
              model: {
                value: item.contacts,
                callback: function($$v) {
                  _vm.$set(item, "contacts", $$v)
                },
                expression: "item.contacts"
              }
            }),
            _c("van-field", {
              attrs: {
                label: "联系电话",
                "label-width": "9.2em",
                placeholder: "请输入"
              },
              model: {
                value: item.phone,
                callback: function($$v) {
                  _vm.$set(item, "phone", $$v)
                },
                expression: "item.phone"
              }
            }),
            _c("van-field", {
              attrs: {
                label: "身份证号",
                "label-width": "9.2em",
                placeholder: "请输入"
              },
              model: {
                value: item.certificates,
                callback: function($$v) {
                  _vm.$set(item, "certificates", $$v)
                },
                expression: "item.certificates"
              }
            }),
            _c("van-cell", {
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function() {
                      return [
                        _c(
                          "van-button",
                          {
                            attrs: {
                              plain: "",
                              round: "",
                              size: "small",
                              type: "info",
                              "native-type": "button"
                            },
                            on: {
                              click: function($event) {
                                return _vm.deleteVehicle(index)
                              }
                            }
                          },
                          [_vm._v(" 删除 ")]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            })
          ],
          1
        )
      }),
      _c(
        "div",
        { staticClass: "button-area" },
        [
          _c(
            "van-button",
            {
              attrs: {
                round: "",
                plain: "",
                type: "info",
                "native-type": "button"
              },
              on: {
                click: function($event) {
                  return _vm.$router.back()
                }
              }
            },
            [_vm._v(" 取消 ")]
          ),
          _c(
            "van-button",
            {
              attrs: { round: "", type: "info", "native-type": "button" },
              on: { click: _vm.submit }
            },
            [_vm._v(" 确定 ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 提货信息 ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "area-title" }, [
      _c("p", { staticClass: "title" }, [_vm._v(" 装车信息 ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }