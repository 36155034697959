<!-- 收货单位 申请提货 -->
<template>
  <div class="van-main apply-delivery-detail">
    <div class="area-title">
      <p class="title">
        提货信息
      </p>
    </div>
    <div class="van-common-detail">
      <van-cell title="支付批次" :value="utils.isEffectiveCommon(form.paymentNo)" :required="true" />
      <van-cell title="提单号" :value="utils.isEffectiveCommon(form.cabinetNo)" :required="true" />
      <van-cell title="订单名称" :value="utils.isEffectiveCommon(form.orderName)" :required="true" />
      <van-cell title="订单编号" :value="utils.isEffectiveCommon(form.orderNo)" :required="true" />
      <van-cell title="货物名称" :value="utils.isEffectiveCommon(form.goodsName)" :required="true" />
      <van-cell title="账面重量(KG)" :value="utils.moneyFormat(stockedDetail.bookWeight)" :required="true" />
      <van-cell title="当前库存重量(KG)" :value="utils.moneyFormat(stockedDetail.stockWeight)" :required="true" />
      <van-cell title="可提重量(KG)" :value="utils.moneyFormat(Number(stockedDetail.bookWeight)-Number(stockedDetail.lockWeight))" :required="true" />
      <van-cell title="入库日期" :value="utils.dateFormat(stockedDetail.finishInTime)" :required="true" />
      <van-cell title="仓库名称" :value="utils.isEffectiveCommon(form.warehouseName)" :required="true" />
      <van-cell title="提货单位" :value="utils.isEffectiveCommon(form.dsEnterpriseName)" :required="true" />
      <van-cell title="历史盈余金额(元)" :value="utils.moneyFormat(form.dsSurplus)" :required="true" />
      <van-cell title="原币单价(USD/KG)" :value="utils.moneyFormat(form.unitPrice)" :required="true" />
      <van-field v-model="form.applyWeight" type="number" :required="true" label="申请提货数量(KG)" label-width="9.2em" placeholder="请输入" />
      <van-cell title="申请提货日期" :value="form.applyDate" is-link :required="true" @click="showApplyDate = true" />
      <van-calendar v-model="showApplyDate" @confirm="confirmApplyDate" />
      <van-field v-model="form.contacts" label="提货联系人" label-width="9.2em" placeholder="请输入" />
    </div>
    <div class="area-title">
      <p class="title">
        装车信息
      </p>
    </div>
    <van-cell>
      <template #default>
        <van-button plain round size="small" type="info" native-type="button" @click="addVehicle()">
          新增装车信息
        </van-button>
      </template>
    </van-cell>
    <van-cell-group v-for="(item, index) in form.vehiclesList" :key="item.id" class="vehicle-cell-group">
      <van-field v-model="item.numberPlates" label="车牌号" label-width="9.2em" placeholder="请输入" />
      <van-field v-model="item.contacts" label="联系人" label-width="9.2em" placeholder="请输入" />
      <van-field v-model="item.phone" label="联系电话" label-width="9.2em" placeholder="请输入" />
      <van-field v-model="item.certificates" label="身份证号" label-width="9.2em" placeholder="请输入" />
      <van-cell>
        <template #default>
          <van-button plain round size="small" type="info" native-type="button" @click="deleteVehicle(index)">
            删除
          </van-button>
        </template>
      </van-cell>
    </van-cell-group>
    <div class="button-area">
      <van-button round plain type="info" native-type="button" @click="$router.back()">
        取消
      </van-button>
      <van-button round type="info" native-type="button" @click="submit">
        确定
      </van-button>
    </div>
  </div>
</template>

<script>
import { Cell, CellGroup, Divider, Button, Tab, Tabs, Toast, Popup, List, NavBar, Calendar, Field, Picker } from 'vant'
export default {
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Toast.name]: Toast,
    [Popup.name]: Popup,
    [List.name]: List,
    [NavBar.name]: NavBar,
    [Calendar.name]: Calendar,
    [Field.name]: Field,
    [Picker.name]: Picker
  },
  data () {
    return {
      batchId: this.$route.query.batchId,
      showApplyDate: false,
      stockedDetail: {},
      form: {
        batchId: this.$route.query.batchId,
        paymentApplicationNumber: '',
        paymentId: '',
        orderId: '',
        orderName: '',
        orderNo: '',
        projectId: '',
        projectName: '',
        projectNo: '',
        contractId: '',
        contractName: '',
        contractNo: '',
        dsEnterpriseId: '',
        dsEnterpriseName: '',
        warehouseId: '',
        warehouseName: '',
        storageManageOrg: '',
        inPlanGoodsId: '',
        cargoId: '',
        goodsName: '',
        goodsLevel: '',
        unitPrice: '',
        applyWeight: '',
        totalPrice: 0,
        applyDate: '',
        contacts: '',
        vehiclesList: []
      }
    }
  },
  computed: {
  },
  created () {
    if (this.batchId) {
      this.getDetail()
    }
  },
  methods: {
    getDetail () {
      this.api.storageManagementByWeight.out.stockedDetail({ batchId: this.batchId }).then(result => {
        this.stockedDetail = result.data.value
        this.form.batchId = this.stockedDetail.batchId
        this.form.paymentApplicationNumber = this.stockedDetail.paymentNo
        this.form.paymentId = this.stockedDetail.paymentId
        this.form.paymentNo = this.stockedDetail.paymentNo
        this.form.orderId = this.stockedDetail.inPlan.orderId
        this.form.orderName = this.stockedDetail.inPlan.orderName
        this.form.orderNo = this.stockedDetail.inPlan.orderNo
        this.form.projectId = this.stockedDetail.inPlan.projectId
        this.form.projectName = this.stockedDetail.inPlan.projectName
        this.form.projectNo = this.stockedDetail.inPlan.projectNo
        this.form.contractId = this.stockedDetail.inPlan.contractId
        this.form.contractName = this.stockedDetail.inPlan.contractName
        this.form.contractNo = this.stockedDetail.inPlan.contractNo
        this.form.dsEnterpriseId = this.stockedDetail.inPlan.dsEnterpriseId
        this.form.dsEnterpriseName = this.stockedDetail.inPlan.dsEnterpriseName
        this.form.usEnterpriseId = this.stockedDetail.inPlan.usEnterpriseId
        this.form.usEnterpriseName = this.stockedDetail.inPlan.usEnterpriseName
        this.form.tenantEnterpriseId = this.stockedDetail.inPlan.tenantEnterpriseId
        this.form.tenantEnterpriseName = this.stockedDetail.inPlan.tenantEnterpriseName
        this.form.tradeEnterpriseId = this.stockedDetail.inPlan.tradeEnterpriseId
        this.form.tradeEnterpriseName = this.stockedDetail.inPlan.tradeEnterpriseName
        this.form.storageEnterpriseId = this.stockedDetail.inPlan.storageEnterpriseId
        this.form.storageEnterpriseName = this.stockedDetail.inPlan.storageEnterpriseName
        this.form.warehouseId = this.stockedDetail.inPlan.warehouseId
        this.form.warehouseName = this.stockedDetail.inPlan.warehouseName
        this.form.storageManageOrg = this.stockedDetail.inPlan.storageManageOrg
        this.form.inPlanGoodsId = this.stockedDetail.inPlanGoodsId
        this.form.goodsName = this.stockedDetail.goodsName
        this.form.cargoId = this.stockedDetail.cargoId
        this.form.goodsLevel = this.stockedDetail.goodsLevel
        this.form.unitPrice = this.stockedDetail.unitPrice
        this.form.cabinetNo = this.stockedDetail.inPlan.goodsList[0].cabinetNo
      }).finally(() => {
      })
    },
    confirmApplyDate (date) {
      this.showApplyDate = false
      this.form.applyDate = this.dateFormat(date)
    },
    addVehicle () {
      this.form.vehiclesList.push({
        numberPlates: '',
        contacts: '',
        phone: '',
        certificates: ''
      })
    },
    deleteVehicle (index) {
      this.form.vehiclesList.splice(index, 1)
    },
    dateFormat (time) {
      return `${time.getFullYear()}-${
        time.getMonth() + 1 >= 10
          ? time.getMonth() + 1
          : '0' + (time.getMonth() + 1)
      }-${time.getDate() >= 10 ? time.getDate() : '0' + time.getDate()}`
    },
    submit () {
      if (!this.form.applyWeight) {
        Toast.fail('请输入申请提货数量')
        return false
      }
      if (!this.form.applyDate) {
        Toast.fail('请选择申请提货日期')
        return false
      }
      return new Promise((resolve, reject) => {
        const formData = this.form
        const api = this.api.storageManagementByWeight.out.applyDelivery
        formData.applyWeight = Number(formData.applyWeight)
        formData.totalPrice = formData.unitPrice * formData.applyWeight
        api(formData).then(result => {
          Toast.success(result.data.message || '提交成功')
          this.$router.back()
          resolve(result.data.value)
        }).catch(e => {
          reject(e)
        }).finally(() => {
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.button-area {
  margin-top: 5px;
  margin-bottom: 20px;
  text-align: center;
  >button {
    width: 100px;
    margin: 0 10px;
  }
}
.apply-delivery-detail {
  margin-bottom: 70px;
  .vehicle-cell-group {
    border:1px solid #ccc;
    border-radius: 0;
    margin: 10px 15px;
  }
}
</style>
